import React, {FunctionComponent, useState} from "react"
import {ManualReconciliationBankAccountType} from "../../reconciliation/account/manual-reconciliation-bank-account.type"
import {DealManualReconciliationBankAccountType} from "./deal-manual-reconciliation-bank-account.type"
import Select from "react-select"
import styles from "../select.module.sass"

type DealManualReconciliationBankAccountSelectAddProps = {
    add: (account: DealManualReconciliationBankAccountType) => void
    manualReconciliationBankAccounts: DealManualReconciliationBankAccountType[]
    manualReconciliationBankAccountsRaw: ManualReconciliationBankAccountType[]
}

const DealManualReconciliationBankAccountSelectAdd: FunctionComponent<DealManualReconciliationBankAccountSelectAddProps> = ({
    add,
    manualReconciliationBankAccounts,
    manualReconciliationBankAccountsRaw
}) => {
    const [selectedAccount, setSelectedAccount] = useState<ManualReconciliationBankAccountType | undefined>()

    const notAlreadySelectedAccounts = manualReconciliationBankAccountsRaw
        .filter(account => !manualReconciliationBankAccounts.map(dealAccount => dealAccount.manualReconciliationBankAccount.id).includes(account.id))

    const onAdd = () => {
        add({
            manualReconciliationBankAccount: selectedAccount!,
            order: 0
        })
    }

    const options = notAlreadySelectedAccounts.map(account => ({
        label: `${account.number} (${account.holderSinglePurposeVehicle.name}, ${account.bank.name}, ${account.transferOrigin})`,
        value: account
    }))

    return (
        <div className={styles.add}>
            {notAlreadySelectedAccounts.length > 0 ? (
                <>
                    <div className={styles.select}>
                        <Select
                            options={options}
                            value={options.filter((o) => selectedAccount?.id === o.value.id)}
                            onChange={val => {
                                if (val && val.value) {
                                    setSelectedAccount(val.value)
                                }
                            }}
                        />
                    </div>
                    <button
                        type="button"
                        onClick={onAdd}
                        disabled={selectedAccount === undefined}
                    >
                        Add
                    </button>
                </>
            ) : (
                <>No more accounts available.</>
            )}
        </div>
    )
}

export default DealManualReconciliationBankAccountSelectAdd