import React, {FunctionComponent, useContext, useEffect, useState} from "react"
import {Link} from "react-router-dom"
import LoadingDots from "../../tech/loading/dots/dots.component"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {InvestmentType} from "../investment/investment.type"
import {INVESTMENT} from "../../paths"
import {formatDate} from "../../tech/format/format.util"
import {compareDates} from "../../tech/sort/sort.util"
import styles from "./dashboard.module.css"

const DashboardPendingInvestments: FunctionComponent = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [investments, setInvestments] = useState<InvestmentType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                setInvestments(await fetchClient.investmentApi.getPendingInvestments())
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    return (
        <div className={styles.box}>
            <div>
                <h3>Pending investments</h3>
                {state === "LOADED" && (
                    <>
                        {investments.length > 0 ? (
                            <>
                                For these investments, the contract has to be signed or payment is due:
                                <ul>
                                    {investments
                                        .sort((a, b) => compareDates(a.created, b.created, "ASCENDING"))
                                        .map(i => (
                                        <li key={i.investor.id}>
                                            <Link to={INVESTMENT(i.id)}>
                                                {i.deal.name.completeOnboarding}:{" "}
                                                {i.investor.person?.fullName}{" "}
                                                <small><strong>({!i.signed ? "Contract not yet signed" : "Not paid yet"})</strong></small>{" "}
                                                <small>[created: {formatDate(i.created)}, last update: {formatDate(i.modified)}, no update for <strong>{getFullDaysBetween(i.modified)} days</strong>]</small>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <>No pending investments found.</>
                        )}
                    </>
                )}
                {state === "LOADING" && <LoadingDots/>}
                {state === "ERROR" && <i>Error while loading.</i>}
            </div>
        </div>
    )
}

export default DashboardPendingInvestments

function getFullDaysBetween(date: Date): number {
    const today = new Date();
    today.setHours(0, 0, 0, 0)
    date.setHours(0, 0, 0, 0)
    const diffTime = today.getTime() - date.getTime()
    return Math.floor(diffTime / (1000 * 60 * 60 * 24))
}