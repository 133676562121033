import {KycLogOpenApi, KycLogReviewOpenApi} from "../../../generated"
import {KycLogReviewType, KycLogType} from "./kyc-log.type"
import {mapOpenApiToInvestor} from "../../investor/investor.mapper"
import {mapOpenApiToFile} from "../../file/file.mapper"

export function mapOpenApiToKycLog(kycLog: KycLogOpenApi): KycLogType {
    return {
        id: kycLog.id,
        created: kycLog.created,
        type: kycLog.type,
        classification: kycLog.classification,
        investor: mapOpenApiToInvestor(kycLog.investor),
        files: kycLog.files.map(f => mapOpenApiToFile(f)),
        comment: kycLog.comment
    }
}

export function mapKycLogReviewToOpenApi(review: KycLogReviewType): KycLogReviewOpenApi {
    return {
        result: review.result,
        comment: review.comment,
        validTill: review.validTill
    }
}